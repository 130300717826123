import React from 'react'
import * as S from './styled'
import './styles.css'
import { ResponsiveImage } from '../../../ResponsiveImage'
import { Link } from '@reach/router'
import Carousel from 'react-multi-carousel'

const responsive = {
  desktop1: {
    breakpoint: { max: 3000, min: 1600 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
  },
  desktop2: {
    breakpoint: { max: 1600, min: 1300 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  desktop3: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet1: {
    breakpoint: { max: 1024, min: 884 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet2: {
    breakpoint: { max: 884, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
}

const URLbase = '/en/about-us/our-team/'

const OurTeam = () => {
  return (
    <S.Wrapper>
      <S.H2Blue>Our team</S.H2Blue>
      <S.UnderlineYellow />
      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlay={true}
        autoPlaySpeed={3000}
        centerMode={false}
        className="NasTym--Carousel--Wrap"
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        showDots={false}
        dotListClass="custom-dot-list-style"
      >
        <Link to={URLbase + 'lukas-eisenwort/'}>
          <ResponsiveImage
            className="img-transform-scale imageBig imageMarginTop"
            src="/images/team/lukas_eisenwort.jpg"
            alt="Lukáš Eisenwort"
            width="252"
          />
          <p className="NasTym--Name">Lukáš Eisenwort</p>
          <p className="NasTym--Position">partner, tax advisor</p>
        </Link>
        <Link to={`${URLbase}jan-kotala/`}>
          <ResponsiveImage
            className="img-transform-scale imageBig imageMarginTop"
            src="/images/team/jan_kotala.jpg"
            alt="Jan Kotala"
            width="252"
          />
          <p className="NasTym--Name">Jan Kotala</p>
          <p className="NasTym--Position">partner, tax advisor</p>
        </Link>
        <Link to={URLbase + 'jan-tecl/'}>
          <ResponsiveImage
            className="img-transform-scale imageBig imageMarginTop"
            src="/images/team/jan_tecl.jpg"
            alt="Jan Tecl"
            width="252"
          />
          <p className="NasTym--Name">Jan Tecl</p>
          <p className="NasTym--Position">partner, tax advisor</p>
        </Link>
        <Link to={URLbase + 'jana-peterkova/'}>
          <ResponsiveImage
            className="img-transform-scale imageBig imageMarginTop"
            src="/images/team/jana_peterkova.jpg"
            alt="Jana Peterková"
            width="252"
          />
          <p className="NasTym--Name">Jana Peterková</p>
          <p className="NasTym--Position">tax manager, tax advisor</p>
        </Link>
        <Link to={URLbase + 'michal-skrabis/'}>
          <ResponsiveImage
            className="img-transform-scale imageBig imageMarginTop"
            src="/images/team/michal_skrabis.jpg"
            alt="Michal Škrabiš"
            width="252"
          />
          <p className="NasTym--Name">Michal Škrabiš</p>
          <p className="NasTym--Position">tax manager, tax advisor</p>
        </Link>
      </Carousel>
      <Link className="button-primary" to={'/en/about-us/our-team/'}>
        Other team members
      </Link>
    </S.Wrapper>
  )
}

export default OurTeam
