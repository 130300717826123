import React from 'react'
import Layout from '../../components/EN/LayoutEN'
import { UnderlineYellow } from '../../components/Typography'
import './styles.css'
import * as S from '../../components/Homepage/OurTeam/styled'
import { graphql } from 'gatsby'
import FormAjax from './../../components/Default/FormSimpleAjaxEN'
import HomepageLanding from '../../components/EN/Homepage/LandingPhoto'
import EKPNumbers from '../../components/EN/Homepage/EKPNumbers'
import OurTeam from '../../components/EN/Homepage/OurTeam'

const EN = ({ data: { page } }) => {
  return (
    <Layout meta={page.frontmatter.meta || false}>
      <HomepageLanding />
      <EKPNumbers />
      <OurTeam />
      <S.Wrapper>
        <div className="container Contact--BlueBackground" id="formAnchor">
          <div>
            <h2 className="h2white">Write us</h2>
            <UnderlineYellow />
            <FormAjax name="HomepageEN" />
          </div>
        </div>
      </S.Wrapper>
    </Layout>
  )
}

export default EN

export const pageQuery = graphql`
  query HomepageEN {
    page: markdownRemark(frontmatter: { slug: { eq: "de" } }) {
      ...Meta
    }
  }
`
