import React from 'react'
import './styles.css'
import { useInView } from 'react-intersection-observer'
import CountUp from 'react-countup'
import Fade from 'react-reveal/Fade'

const EKPNumbers = () => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.25,
    triggerOnce: true
  })
  return (
    <div className="EKPNumbers--Wrapper">
      <div className="row align-items-center" ref={ref}>
        <div className="col-md-6 col-lg-3">
          <h2>EKP in numbers</h2>
          <div className="EKPNumbers--Underline" />
        </div>
        <div className="col-md-6 col-lg-3">
          <Fade bottom>
            <div className="EKPNumbers--Number">
              <CountUp start={0} end={18} duration={inView ? 5 : 0}></CountUp>+
            </div>
            <div className="EKPNumbers--Label">tax advisors</div>
          </Fade>
        </div>
        <div className="col-md-6 col-lg-3">
          <Fade bottom delay={250}>
            <div className="EKPNumbers--Number">
              <CountUp start={0} end={60} duration={inView ? 4 : 0}></CountUp>+
            </div>
            <div className="EKPNumbers--Label">colleagues</div>
          </Fade>
        </div>
        <div className="col-md-6 col-lg-3">
          <Fade bottom delay={500}>
            <div className="EKPNumbers--Number">
              <CountUp start={0} end={350} duration={inView ? 3 : 0}></CountUp>+
            </div>
            <div className="EKPNumbers--Label">clients</div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default EKPNumbers
